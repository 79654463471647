const LinkExternalAnnotation = ({children, mark}) => {
  return (
    <a
      href={mark?.url}
      rel="noopener noreferrer"
      target={mark?.newWindow ? '_blank' : '_self'}
    >
      <>{children}</>
    </a>
  );
};

export default LinkExternalAnnotation;
