import { Link } from 'react-router-dom';

const Header = (props) => {

  const { location, siteData } = props;
  const { pathname } = location;
  const { donateLink } = siteData;

  return (
    <header className="header">
      <Link to="/" className="header__link">
        <h1 className="title">Blatta o Plomo</h1>
      </Link>
      <div className="header__link-separator long" />
      {
        donateLink && donateLink !== '' &&
        <a href={donateLink} rel="noreferrer" target="_blank" className="header__link">Buy</a>
      }
      {
        donateLink && donateLink !== '' &&
        <div className="header__link-separator" />
      }
      <Link
        to={pathname !== '/info' ? '/info' : '/'}
        className={`${pathname === '/info' ? 'active ' : ''}header__link`}
      >{pathname !== '/info' ? 'Info' : 'Close'}</Link>
    </header>
  )
};

export default Header;