import { Fragment } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './Header';
import Video from './Video';
import Info from './Info';
import MarqueeBottom from './MarqueeBottom';
import { AnimatePresence } from 'framer-motion';

const Content = (props) => {
  
  const { siteData } = props;
  const location = useLocation();

  return (
    <div className="app">
      <Header {...props} location={location} />
      <Video {...props} location={location} />
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            path="info"
            element={
              <Info {...props} />
            }
          />
          <Route path="*" element={<Fragment />} />
        </Routes>
      </AnimatePresence>
      {
        siteData.ctaMarqueeText &&
        <MarqueeBottom {...props} />
      }
    </div>
  )
};

export default Content;