import { useRef, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import groq from 'groq';
import sanityClient from './utils/sanityClient';
import Content from './components/Content';

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  const videoElement = useRef();
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    sanityClient.fetch(
      groq`*[
        _type == 'home'
      ][0]{
        "video": video.asset->url,
        "videoPoster": videoPoster.asset->url,
        ctaMarqueeText,
        ctaMarqueeLink,
        donateLink,
        info
      }
    `)
      .then(data => {
        setSiteData(data);
      })
      .catch(err => console.log(err));
  }, []);
  
  useEffect(() => {
    const handleClick = () => {
      videoElement.current.play().catch(e => console.error(e));
    }

    const handleResize = () => {
      setWindowDimensions({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    window.addEventListener('resize', handleResize);

    if (isPlaying === false) {
      window.addEventListener('click', handleClick);
    }

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('resize', handleResize);
    }
  }, [isPlaying]);

  return (
    <BrowserRouter basename="/">
      <Content 
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        videoElement={videoElement}
        {...windowDimensions}
        siteData={siteData}
      />
    </BrowserRouter>
  );
}

export default App;
