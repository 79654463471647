import { Fragment } from 'react';

const Video = (props) => {

  const { isPlaying, setIsPlaying, videoElement, location, siteData } = props;
  const { video, videoPoster } = siteData;

  return (
    <Fragment>
      {
        videoPoster &&
        <img
          className="video__img"
          src={videoPoster}
          alt="Big ceramic piggy bank of Sepp Blatter’s head surrounded by piles of coins"
        />
      }
      {
        video &&
        <video
          src={video}
          className={`video--main${location.pathname === '/about' ? ' video--about' : ''}`}
          ref={videoElement}
          muted={true}
          style={{
            opacity: isPlaying === true ? 1 : 0
          }}
          loop={true}
          autoPlay={true}
          onPlay={() => setIsPlaying(true)}
          playsInline={true}
        />
      }
    </Fragment>
  )
};

export default Video;