import { Fragment, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Marquee from 'react-fast-marquee';
import LackOfGuidanceLogo from './LackOfGuidanceLogo';
import LackOfGuidanceLogoMarque from './LackOfGuidanceLogoMarque';

const MarqueeBottom = (props) => {

  const { siteData } = props;
  const { ctaMarqueeText, ctaMarqueeLink } = siteData;
  const [lackOfGuidanceLogoIsHovered, setLackOfGuidanceLogoIsHovered] = useState(false);

  return (
    <div className="marquee__wrapper"
      onMouseEnter={() => setLackOfGuidanceLogoIsHovered(true)}
      onMouseLeave={() => setLackOfGuidanceLogoIsHovered(false)}
      onBlur={() => setLackOfGuidanceLogoIsHovered(false)}
    >
      {
        ctaMarqueeText && ctaMarqueeText !== '' &&
        <Marquee gradient={false}>
          {
            Array(10).fill(ctaMarqueeText).map(
              (value, i) => (
                <Fragment key={i}>
                  {
                    ctaMarqueeLink && ctaMarqueeLink !== '' ?
                      <a href="/" rel="noreferrer" target="_blank" className="marquee__link">
                        <p>{value}</p>
                      </a>
                      :
                      <span className="marquee__link">
                        <p>{value}</p>
                      </span>
                  }
                  <div className="marquee__separator" />
                  <div className="marquee__lack-of-guidance-logo-marque__wrapper">
                    <LackOfGuidanceLogoMarque />
                  </div>
                  <div className="marquee__separator" />
                </Fragment>
              )
            )
          }
        </Marquee>
      }
      <AnimatePresence>
        {
          lackOfGuidanceLogoIsHovered === true &&
          <motion.div
            initial={{ x: '120%', y: '120%' }}
            animate={{ x: 0, y: 0 }}
            exit={{ x: '120%', y: '120%' }}
            transition={{ style: 'ease' }}
            className="lack-of-guidance-logo-wrapper"
            >
              <a href="https://lackofguidance.com/" target="_blank" rel="noreferrer" className="lack-of-guidance-logo__link">
                <LackOfGuidanceLogo />
              </a>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
};

export default MarqueeBottom;