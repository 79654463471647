import BlockContent from '@sanity/block-content-to-react';
import Block from './Block';
import ListBlock from './ListBlock';
import LinkEmailAnnotation from './annotations/LinkEmail';
import LinkExternalAnnotation from './annotations/LinkExternal';

export default function RichText({ blocks }) {
  return (
    <BlockContent
      blocks={blocks}
      renderContainerOnSingleChild
      serializers={{
        // Lists
        list: ListBlock,
        // Annotations
        marks: {
          annotationLinkEmail: LinkEmailAnnotation,
          annotationLinkExternal: LinkExternalAnnotation
        },
        // Block types
        types: {
          block: Block,
        },
      }}
    />
  );
}
