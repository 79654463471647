import { motion } from 'framer-motion';
import RichText from './RichText/RichText';

const Info = (props) => {

  const { siteData } = props;
  const { info } = siteData;
  
  return (
    <motion.div
      initial={{ x: '120%' }}
      animate={{ x: 0 }}
      exit={{ x: '120%' }}
      transition={{ style: 'ease' }}
      className="info"
    >
      <div className="info__inner">
        {
          info && info[0] &&
          <RichText blocks={info} />
        }
      </div>
    </motion.div>
  )
};

export default Info;